<template>
    <div class="animated fadeIn">
        <CCard>
            <CCardHeader><font-awesome-icon icon="folder-open"/> Forms</CCardHeader>
            <CCardBody>
                
                <CTabs :active-tab="my_tab" @update:activeTab="changeTab">
                    <br>  
                    <CTab>
                        <template slot="title">
                            Active Form/s <CBadge color="success">( {{ [...new Set(form_list_active.map((item) => item.name))].length }} )</CBadge>
                        </template>
                        <CRow>
                            <CCol lg="12">
                                <CDataTable
                                    :items="form_list_active"
                                    :fields="form_list_fields"
                                    :table-filter="{ placeholder: 'Your text here.', label: 'Search:'}"
                                    :items-per-page="10"
                                    border
                                    items-per-page-select
                                    sorter
                                    pagination
                                    :loading="this.table_loading"
                                >
                                <template slot="no-items-view" v-if="this.table_loading">
                                    <center>
                                        <CSpinner
                                            style="width:4rem;height:4rem;"
                                            color="info"
                                        />
                                    </center>
                                </template>
                                <template #Action="{item}">
                                    <td>
                                        <router-link :to="{path: `/${$store.getters['getOrganizationName']}/form-management/form/${item.id}`}">
                                            <CButton 
                                                color="info" 
                                                shape="pill" 
                                                size="sm"
                                            >
                                                <font-awesome-icon icon="cog"/> Manage
                                            </CButton>
                                        </router-link>
                                        &nbsp;
                                        <CButton 
                                            color="dark" 
                                            shape="pill" 
                                            size="sm"
                                            :disabled="previewing_form ? (item.id == selected_row_id ? true : false) : false"
                                            @click="previewForm(item)"
                                        >
                                            <font-awesome-icon :icon="previewing_form ? (item.id == selected_row_id ? 'circle-notch' : 'eye') : 'eye'" 
                                                                    :pulse="previewing_form ? (item.id == selected_row_id ? true : false) : false"/> Preview Form
                                        </CButton>
                                        &nbsp;
                                        <CButton 
                                            color="warning" 
                                            shape="pill" 
                                            size="sm"
                                            @click="copyForm(item)"
                                        >
                                                <font-awesome-icon icon="copy"/> Copy Form
                                        </CButton>
                                    </td>
                                </template>
                                <template #created_at="{item}">
                                    <td>
                                        {{ changeDateFormatV2(item.created_at) }}
                                    </td>
                                </template>
                                </CDataTable>
                            </CCol>
                        </CRow>
                    </CTab>
                    <CTab>
                        <template slot="title">
                            Inactive Form/s <CBadge color="danger">( {{ [...new Set(form_list_inactive.map((item) => item.name))].length }} )</CBadge>
                        </template>
                        <CRow>
                            <CCol lg="12">
                                <CDataTable
                                    :items="form_list_inactive"
                                    :fields="form_list_fields"
                                    :table-filter="{ placeholder: 'Your text here.', label: 'Search:'}"
                                    :items-per-page="10"
                                    border
                                    items-per-page-select
                                    sorter
                                    pagination
                                    :loading="this.table_loading"
                                >
                                <template slot="no-items-view" v-if="this.table_loading">
                                    <center>
                                        <CSpinner
                                            style="width:4rem;height:4rem;"
                                            color="info"
                                        />
                                    </center>
                                </template>
                                <template #Action="{item}">
                                    <td>
                                        <template v-if="$store.getters['can']('restore-form')">
                                            <CButton 
                                                    size="sm"
                                                    shape="pill"
                                                    color="success" 
                                                    :disabled="restoring ? (item.id == restoring_id ? true : false) : false"
                                                    @click="restore(item)"
                                                >

                                                <font-awesome-icon :icon="restoring ? (item.id == restoring_id ? 'circle-notch' : 'trash-restore') : 'trash-restore'" 
                                                            :pulse="restoring ? (item.id == restoring_id ? true : false) : false"/> Restore
                                            </CButton>
                                        </template>
                                        <template v-else>
                                            <i><b>No permission to restore.</b></i>
                                        </template>
                                    </td>
                                </template>
                                <template #updated_at="{item}">
                                    <td>
                                        <small>{{ changeDateFormat(item.updated_at) }}</small>
                                    </td>
                                </template>
                                <template #preview="{item}">
                                    <td>
                                        <center>
                                            <CButton 
                                                color="warning" 
                                                shape="pill" 
                                                size="sm"
                                                :disabled="previewing_form ? (item.id == selected_row_id ? true : false) : false"
                                                @click="previewForm(item)"
                                            >
                                                <font-awesome-icon :icon="previewing_form ? (item.id == selected_row_id ? 'circle-notch' : 'eye') : 'eye'" 
                                                                        :pulse="previewing_form ? (item.id == selected_row_id ? true : false) : false"/>
                                            </CButton>
                                        </center>
                                    </td>
                                </template>
                                </CDataTable>
                            </CCol>
                        </CRow>
                    </CTab>
                </CTabs>


            </CCardBody>
        </CCard>   
        <CModal 
            :closeOnBackdrop="false"
            color="primary" 
            :show.sync="form_preview" 
            centered
            size="xl"
        >
            <template #header>
                <h5><font-awesome-icon icon="folder-open" /> {{preview_form.form_name}}</h5>
                <CButton
                    class="pull-right"
                    color="light"
                    shape="pill"
                    size="sm"
                    @click="form_preview = false"
                >
                    <font-awesome-icon icon="times" />
                </CButton>
            </template>
            <template #body-wrapper v-if="form_preview">
                <FormPreview ref="formPreview"/>
            </template>
            <template #footer>
                <hr>
            </template>
        </CModal>
    </div>
</template>
<script>
import FormPreview from './components/FormPreview.vue';
import FormManagementJS from '../js/form-management.js';
export default {
    name: 'FormLists',
    components: {
        FormPreview,
    },
    data(){
        return {
            table_loading: true,
            selected_row_id: null,
            previewing_form:false,
            form_preview: false,
            preview_form: {
                form_name:null,
            },
            form_list: [
                // {id: 1, name: 'CRF', status: 'Active'},
                // {id: 2, name: 'PRS', status: 'Active'},
                // {id: 3, name: 'PO', status: 'Active'},
            ],
            restoring: false,
            my_tab: 0,
            restoring_id: null,
        }
    },
    created() {
        this.getForms();
        this.$emit('activeTab', 0);
    },
    computed: {
        form_list_active: function () {
            return this.form_list.filter((item) => item.deleted_at == null);
        },
        
        form_list_inactive: function () {
            return this.form_list.filter((item) => item.deleted_at != null);
        },

        form_list_fields: function () {
            let custom_fields= []

            if(this.my_tab) {
                custom_fields.push({ key: 'preview', label: ' ', _classes: 'th-icon' })
            }
            
            custom_fields.push({ key: 'name', label: 'Name'},)
            
            if(this.my_tab) {
                custom_fields.push({ key: 'updated_at', label: 'Inactivity Date' })
            } else {
                custom_fields.push({ key: 'created_at', label: 'Date Added' })
            }

            if(this.$store.getters.can('view-form')) {
                custom_fields.push({ key: 'Action', sorter: false})
            }
            return custom_fields;
        }
    },
    methods: {
        copyForm: function (item) {
            this.$swal({
                icon: "warning",
                title: "Are you sure?",
                text: `You are trying to copy the form selected. Please note that this action will create a new form that is identical to the selected form.`,
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes",
                showLoaderOnConfirm: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                preConfirm: () => { 
                    this.$Progress.start()
                    return axios.post('/form-managements/form/copy-form', {form_id: item.id}, {validateStatus: () => true})
                    .then(response => {
                        if(response.status == 200) {
                            this.$swal({
                                icon: "success",
                                title: "Success!",
                                text: `${response.data.data.name} form has sucessfully added. Do you want to open this form?`,
                                showCancelButton: true,
                                confirmButtonColor: "#DD6B55",
                                confirmButtonText: "Yes, open form",
                            }).then((result) => {
                                if(result.value){ 
                                    this.$router.push(`/${this.$store.getters.getOrganizationName}/form-management/form/${response.data.data.id}`);
                                } else {
                                    this.getForms()
                                }
                            })
                            this.$Progress.finish();
                        }
                    })
                }
            }).then((result) => {
                if(!result.value){
                    this.$swal('Cancelled!','','error');
                }
            })
        },
        restore: function (item) {
            this.restoring = true
            this.restoring_id = item.id;
            this.$Progress.start()
            axios.post('/form-managements/form/restore/' + item.id, {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    this.$swal({
                        toast: true,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        icon: 'success',
                        title: `${item.name} successfully restored.`,
                        timerProgressBar:true,
                    })
                    this.form_list.filter(obj => obj.id === item.id)[0].deleted_at = null;
                    this.$Progress.finish() 
                }
            })
        },
        changeTab: function (item) {
            this.my_tab = item
        },      
        getForms: function () {
            this.table_loading = true  
            this.$Progress.start()
            axios.get('/form-managements/form/list', {validateStatus: () => true}).then(response => {
                if(response.status == 200) {
                    this.form_list = response.data.data
                    this.table_loading = false;
                    this.$Progress.finish()
                }
            })
        },
        previewForm: function (item) {
            this.selected_row_id = item.id;
            this.$Progress.start()
            this.previewing_form = true
            axios.get('/form-managements/form/show/'+item.id, {validateStatus: () => true}).then(response => {
                if(response.status == 200) {
                    this.previewing_form = false;
                    this.preview_form.form_name = item.name;
                    this.form_preview = true
                    let header_footer_field = FormManagementJS.fixedHeaderFooterFieldsArray(response.data.data.form_header_footer_fields);
                    let detail_field = FormManagementJS.fixedDetailFieldsArray(response.data.data.form_detail_fields)
                    this.$nextTick(() => {
                        this.$refs.formPreview.form.footer_column = response.data.data.footer_column;
                        this.$refs.formPreview.form.header_column = response.data.data.header_column;
                        this.$refs.formPreview.headerFieldsComps = FormManagementJS.generateHeaderFooterField(header_footer_field, 'header');
                        this.$refs.formPreview.detail_field = detail_field;
                        this.$refs.formPreview.footerFieldsComps = FormManagementJS.generateHeaderFooterField(header_footer_field, 'footer');
                    })
                    this.$Progress.finish()
                }
            })
        }
    }
}
</script>
<style>

.th-icon {
    width: 70px !important;
}
</style>